<template>
  <div class="row">
    <div :class="'col-lg-12'">
      <div class="card">
        <div class="card-body">
          <!-- <form class="form-horizontal"> -->
          <form action="#" @submit.prevent="showModal({type:'submit',data:payload.response_dispo})">
            <div class="form-body">              
              <h3 class="box-title">Jawab Disposisi</h3>
              <hr class="m-t-0">
                <div class="row m-t-10">
                  <div class="col-md-12">
                    <div class="form-check">
                      <input 
                      type="checkbox" 
                      v-model="isFalseDispo"
                      class="form-check-input"                                             
                      >
                      <label class="form-check-label" for="wrong_send">Salah kirim</label>
                    </div>
                  </div>
                </div>
                <div class="row m-t-10" v-if="!isFalseDispo">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">Keterangan</label>
                  </div>
                </div>
                <div class="row" v-if="!isFalseDispo">
                  <div class="col-md-12 m-t-10 m-b-10">
                    <wysiwyg v-model="myHTML"/>
                  </div>
                </div>
            </div>
            <hr>

            <div class="form-actions">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-9">
                      <button
                        type="button"
                        @click="submit()"
                        class="btn btn-inverse"
                        v-if="(!isFalseDispo  && canDispo) && !isPersonal"
                        title="Teruskan Disposisi"
                      >Teruskan</button>&nbsp;
                      <button
                        type="button"
                        @click="done()"
                        class="btn btn-danger"
                        title="Selesai Disposisi"
                      >Selesai</button>&nbsp;
                      <!-- <button
                        type="button"
                        @click="goBack()"
                        class="btn btn-inverse"
                      >Cancel</button> -->
                      <button type="button"  @click="goBack" class="btn btn-inverse" title="Cancel">Cancel</button>

                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>

          </form>
          <ModalDefault v-show="isVisible" :title="modalTitle" :content="modalContent" :action="ActionChangeable" :confirmation="isConfirmation" @close="onClose" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Upload,
    Input,
    Multiselect
  },
  computed: {
    canDispo(){
      if(this.$store.state.profile.permissions.disposition_in){
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
    isPersonal(){
      if(this.$store.state.dispositionIn.detail.jenis_dispo == 'Personal'){
        return true;
      } else {
        return false;
      }
    },
    isInvalid() {
      return this.isTouched && this.value.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidPersonal() {
      return (
        this.dropdownPersonal.isTouched &&
        this.dropdownPersonal.value.length === 0
      );
    }
  },
  data() {
    const uploader = this.fineUploader(this,'disposition_response');
    return {
      uploader,
      myHTML: "",
      // response_dispo: "",
      payload: {
        response_dispo: "",
      },
      isFalseDispo : false      
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    onClose(){
      this.isVisible = false;
    },
    showModal(option){
      this.isVisible = true;
      if(option.type == 'submit'){
        if(option.data == 1){
          this.modalTitle = "Teruskan Disposisi?";
          this.modalContent = "<p>Apakah anda yakin ingin meneruskan disposisi?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        }else if(option.data == 0){
          this.modalTitle = "Selesai Disposisi?";
          this.modalContent = "<p>Apakah anda yakin ingin mengelesaikan disposisi?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        }
      }else if(option.type == 'cancel'){
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    async initialize() {
      try {
        this.$store.dispatch("dispositionIn/getDispositionInById", this.$route.params);
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    goBack() {
      this.$store.dispatch("dispositionIn/onCancel");
    },
   
    submit() {
      const payload = {
        agenda_number: this.$store.state.dispositionIn.detail.agenda_number,
        dispoid: this.$store.state.dispositionIn.detail.id,
        is_false: this.isFalseDispo,
        description: this.myHTML,
        response_dispo: "1"
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("dispositionIn/submitJawabDispo", {data, id: this.$route.params.dispoid});
          } else {
            this.$store.dispatch("dispositionIn/submitJawabDispo", {data, id: this.$route.params.dispoid});
          }
        }
      });
    },
    done() {
      const payload = {
        agenda_number: this.$store.state.dispositionIn.detail.agenda_number,
        is_false: this.isFalseDispo,
        dispoid: this.$store.state.dispositionIn.detail.id,
        description: this.myHTML,
        response_dispo: "0"
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("dispositionIn/submitJawabDispo", {data, id: this.$route.params.dispoid});
          } else {
            this.$store.dispatch("dispositionIn/submitJawabDispo", {data, id: this.$route.params.dispoid});
          }
        }
      });
    },

    // done() {
    //   const payload = {
    //     agenda_number: this.$store.state.dispositionIn.detail.agenda_number,
    //     description: this.myHTML,
    //     response_dispo: "0"
    //   };
    //   const data = JSON.stringify(payload);
    //   this.$validator.validateAll().then(success => {
    //     if (success) {
    //       this.$store.dispatch("dispositionIn/submitJawabDispo",data);
    //     }
    //   });
    // },

  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

